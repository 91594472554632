import Check from '@sats-group/icons/16/check';
import React from 'react';

import Flag from 'sats-ui-lib/react/flag';
import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import type { ThemedComponent } from 'client/types';
import Price from 'components/price/price';

import type { MembershipCard as Props } from './membership-card.types';
import { themes } from './membership-card.types';

const MembershipCard: ThemedComponent<Props, typeof themes> = ({
  details,
  hideCheckmark,
  id,
  isMultiSelect,
  isSelected,
  price,
  tag,
  tagline,
  theme = themes.full,
  title,
}) => {
  const check = hideCheckmark ? undefined : (
    <div
      className={mod('membership-card__check', {
        checked: isSelected,
        multi: isMultiSelect,
      })}
    >
      {isSelected ? <Check /> : null}
    </div>
  );

  const content =
    theme === themes.simple ? (
      <div className="membership-card__button">
        <div>{check}</div>
        <Text theme={Text.themes.normal} size={Text.sizes.button}>
          {title}` (${price?.current.value} ${price?.current.post})`
        </Text>
      </div>
    ) : (
      <React.Fragment>
        {check}

        <Text
          className="membership-card__title"
          elementName="h3"
          size={Text.sizes.headline3}
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>

        {tag ? (
          <div className="membership-card__tag">
            <Flag text={tag} />
          </div>
        ) : null}

        {price ? (
          <div className="membership-card__price">
            <Price {...price} />
          </div>
        ) : null}

        {tagline ? (
          <Text className="membership-card__tagline" size={Text.sizes.small}>
            {tagline}
          </Text>
        ) : null}

        {details ? (
          <Text className="membership-card__details" size={Text.sizes.small}>
            {details}
          </Text>
        ) : null}
      </React.Fragment>
    );

  return (
    <div
      aria-current={isSelected}
      className={mod('membership-card', theme, { 'has-tag': Boolean(tag) })}
      key={id}
    >
      {content}
    </div>
  );
};

MembershipCard.themes = themes;

export default MembershipCard;
