import React from 'react';

import Banner from 'sats-ui-lib/react/banner';
import Text from 'sats-ui-lib/react/text';

import type { PtShopConfirmation as Props } from './pt-shop-confirmation.types';
import ContentContainer from 'client/components/content-container/content-container';
import Logo from 'client/components/logo/logo';
import LinkButton from 'sats-ui-lib/react/link-button';

const PtShopConfirmation: React.FC<Props> = ({
  home,
  information,
  logo,
  messages,
  paymentInformation,
  productInformation,
  title,
}) => (
  <div className="pt-shop-confirmation">
    <ContentContainer>
      <div className="pt-shop-confirmation__content">
        <header className="pt-shop-confirmation__logo">
          <Logo {...logo} />
        </header>
        <main className="pt-shop-confirmation__box">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
          <Text>{information}</Text>
          {productInformation.length || paymentInformation.length ? (
            <div className="pt-shop-confirmation__descriptions-wrapper">
              {productInformation.length ? (
                <dl className="pt-shop-confirmation__description-list">
                  {productInformation.map(line => (
                    <div className="pt-shop-confirmation__line-item">
                      <Text elementName="dt" theme={Text.themes.emphasis}>
                        {line.name}
                      </Text>
                      <Text elementName="dd">{line.value}</Text>
                    </div>
                  ))}
                </dl>
              ) : null}
              {paymentInformation.length ? (
                <dl className="pt-shop-confirmation__description-list">
                  {paymentInformation.map(line => (
                    <div className="pt-shop-confirmation__line-item">
                      <Text elementName="dt" theme={Text.themes.emphasis}>
                        {line.name}
                      </Text>
                      <Text elementName="dd">{line.value}</Text>
                    </div>
                  ))}
                </dl>
              ) : null}
            </div>
          ) : null}
          {messages.length ? (
            <div>
              {messages.map(entry => (
                <div key={entry.text}>
                  <Banner {...entry} />
                </div>
              ))}
            </div>
          ) : null}
          <div className="pt-shop-confirmation__home">
            <LinkButton variant={LinkButton.variants.secondary} {...home} />
          </div>
        </main>
      </div>
    </ContentContainer>
  </div>
);

export default PtShopConfirmation;
